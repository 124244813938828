<script setup lang="ts">
import { Product } from '@shopware-pwa/types'
const { addProduct } = useCart()
const { getFormattedPrice } = usePrice()
const { pushSuccess } = useNotifications()
const { codeErrorsNotification } = useCartNotification()
const { t } = useI18n()
const { open } = useCartModal()

const props = defineProps<{
  product: Product
  label?: string
}>()

const onClick = () => {
  addProduct(props.product)
  codeErrorsNotification()
  pushSuccess(`${props.product?.translated?.name} ${t('shop.cartPage.hasBeenAddedToTheCart')}`)
  open()
}
</script>
<template>
  <Button primary icon="cart" @click.stop="onClick">
    {{ label }}
  </Button>
</template>
